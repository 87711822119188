import {
  type ChangeEvent,
  type KeyboardEvent,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useRouter } from "next/compat/router";
import useSWR from "swr";
import {
  Box,
  Text,
  Heading,
  IconFa,
  Loader,
  ErrorText,
  Pagination,
  crukTheme,
} from "@cruk/cruk-react-components";

import { useTracking } from "@fwa/src/hooks/useTracking";
import {
  searchUrlHomepageSearch,
  createHomePageQueryRequestBody,
} from "@fwa/src/services/searchService";
import { fetcher } from "@fwa/src/services/apiClient";
import { numberWithCommas } from "@fwa/src/utils/formatUtils";

import { SearchResultHomePage } from "@fwa/src/components/SearchResultHomePage";

import {
  ContentWrapperResponsive,
  List,
  RowBottom,
} from "@fwa/src/components/styles";
import {
  SearchWrapper,
  StyledForm,
  SearchTextField,
  SearchButton,
  SearchSuggestionsWrapper,
  SearchResultsWrapper,
  CustomTextResultsCounter,
  SearchResultUl,
  SearchResultOl,
  Label,
  LabelText,
  LabelTextHeading,
} from "@fwa/src/components/HomePageSearch/styles";
import { type SearchHitsType, type SearchResultType } from "@fwa/src/types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const ITEMS_PER_PAGE = 10;

export const HomePageSearch = () => {
  const { trackEventGtm, trackError } = useTracking();
  const router = useRouter();
  const { q, page } = router?.query || {};
  const searchQuery: string = q ? q.toString() : "";
  const currentPage: number = page ? parseInt(page.toString(), 10) : 1;
  const [searchString, setSearchString] = useState<string>(searchQuery);
  const [text, setText] = useState<string>(searchQuery);
  const [urlKey, setUrlKey] = useState<string | null>(null);
  const [searchItemOffset, setSearchItemOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const resultsContainerRef = useRef<HTMLDivElement>(null);

  const searchFetcher = () =>
    fetcher<SearchHitsType>(searchUrlHomepageSearch() || "", {
      method: "POST",
      body: JSON.stringify(
        createHomePageQueryRequestBody(
          searchItemOffset,
          ITEMS_PER_PAGE,
          searchString,
        ),
      ),
    });

  const { data: searchResponse, error } = useSWR(urlKey, searchFetcher, {});

  const showingText = searchResponse?.hits.total
    ? `Showing ${searchItemOffset + 1} to ${
        searchItemOffset + searchResponse.hits.hits.length
      } of ${numberWithCommas(searchResponse.hits.total)} results`
    : "";

  //  always reset to page 1
  const setSearchHash = () => {
    router
      ?.push(
        {
          pathname: router.pathname,
          query: { ...router.query, q: text, page: 1 },
        },
        undefined,
        { shallow: true },
      )
      .catch((err) => {
        trackError(err as Error, { component: "HomePageSearch" });
      });
  };

  // We are effectively storing page number in the query string
  const setPage = (pageNumber: number) => {
    router
      ?.push(
        {
          pathname: router.pathname,
          query: { ...router.query, page: pageNumber },
        },
        {
          pathname: router.pathname,
          query: { ...router.query, page: pageNumber },
        },
        { shallow: true },
      )
      .catch((err) => {
        trackError(err as Error, { component: "HomePageSearch" });
      });
  };

  const handleSearchClick = () => {
    if (text === searchString) return;
    setSearchHash();
  };

  const doSearch = useCallback(
    ({
      searchTerm,
      searchPage,
    }: {
      searchTerm: string;
      searchPage: number;
    }) => {
      if (searchTerm.length < 1) return;
      setIsLoading(true);
      setUrlKey(`${searchUrlHomepageSearch()}-${searchTerm}-${searchPage}`);
    },
    [],
  );

  const handleTextChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setText(target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Find") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  // if pagination changes, update item offset used in query
  useEffect(() => {
    setSearchItemOffset(ITEMS_PER_PAGE * Math.max(currentPage - 1, 0));

    if (searchResponse || error) {
      resultsContainerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentPage, searchResponse, error]);

  // if search query string changes update state
  useEffect(() => {
    setText(searchQuery);
    setSearchString(searchQuery);
  }, [searchQuery]);

  // if either of the 2 above changes do new search request
  useEffect(() => {
    if (searchString.length)
      doSearch({ searchTerm: searchString, searchPage: currentPage });
  }, [currentPage, doSearch, searchString]);

  // after we get an error track it
  useEffect(() => {
    if (error && trackError) {
      setIsLoading(false);
      trackError(error as Error, { component: "HomepageSearch" });
    }
  }, [error, trackError]);

  // after we get a response from our query set loading to false and scroll to content
  useEffect(() => {
    if (searchResponse && trackEventGtm) {
      setIsLoading(false);
      trackEventGtm({
        event: "site_search",
        numberOfResults: searchResponse.hits.total || 0,
      });
    }
  }, [searchResponse, trackEventGtm]);

  return (
    <>
      {/* ---------------------- Search section -------------------------------- */}

      <ContentWrapperResponsive
        marginBottom="none"
        data-component="home-page-search"
      >
        <SearchWrapper ref={resultsContainerRef}>
          <RowBottom>
            <StyledForm action="">
              <Label>
                <LabelTextHeading>Find a Giving Page</LabelTextHeading>
                <LabelText>Search for friends and teams...</LabelText>
                <SearchTextField
                  label=""
                  type="search"
                  name="search"
                  aria-label="Search friends, teams and events"
                  value={text}
                  autoComplete="off"
                  onChange={handleTextChange}
                  onKeyDown={handleKeyDown}
                />
              </Label>
            </StyledForm>
            <SearchButton
              type="button"
              appearance="tertiary"
              aria-label="search"
              onClick={handleSearchClick}
            >
              <IconFa faIcon={faSearch} size="1.5em" />
            </SearchButton>
          </RowBottom>
        </SearchWrapper>
      </ContentWrapperResponsive>

      {/* ---------------------- Search results -------------------------------- */}
      {isLoading ? <Loader /> : null}
      {!!error && (
        <Box padding="s" marginBottom="none">
          <ErrorText>Something went wrong please try again later</ErrorText>
        </Box>
      )}

      {!!searchResponse && !searchResponse.hits.hits.length && (
        <SearchSuggestionsWrapper marginVertical="s">
          <Text marginHorizontal="none" role="alert" marginBottom="s">
            0 results found
          </Text>
          <ContentWrapperResponsive
            backgroundColor={crukTheme.tokenColors.darkBlue_100}
            marginHorizontal="none"
            marginTop="s"
          >
            <Box
              backgroundColor={crukTheme.tokenColors.darkBlue_100}
              padding="none"
            >
              <Heading h3 marginLeft="xxs" marginBottom="none">
                Search suggestions:
              </Heading>
              <SearchResultOl>
                <li>Check your spelling</li>
                <li>
                  Try searching for different information. You can search by:
                  <SearchResultUl>
                    <li>Fundraiser name</li>
                    <li>Fundraiser email</li>
                    <li>Team name</li>
                    <li>Team members</li>
                    <li>Event name</li>
                    <li>Page name</li>
                    <li>Description</li>
                    <li>Who the page is in memory of</li>
                  </SearchResultUl>
                </li>
              </SearchResultOl>
            </Box>
          </ContentWrapperResponsive>
        </SearchSuggestionsWrapper>
      )}
      {!!searchResponse && !!searchResponse.hits.hits.length && (
        <SearchResultsWrapper paddingHorizontal="none" paddingTop="s">
          <CustomTextResultsCounter role="alert">
            {showingText}
          </CustomTextResultsCounter>
          <Box>
            <List>
              {searchResponse.hits.hits.map((result: SearchResultType) => (
                <li key={result._id}>
                  <SearchResultHomePage
                    result={result}
                    searchQuery={searchQuery}
                  />
                </li>
              ))}
            </List>
          </Box>
          {searchResponse.hits.total > ITEMS_PER_PAGE && (
            <Pagination
              id="home-page-search-pagination-change"
              current={currentPage}
              perPage={ITEMS_PER_PAGE}
              items={searchResponse.hits.total}
              hideLast
              pagerCallback={(n: number) => {
                trackEventGtm({
                  event: "search_page_change",
                  currentPage: n,
                });
                setPage(n);
              }}
            />
          )}
        </SearchResultsWrapper>
      )}
    </>
  );
};

export default HomePageSearch;
